module.exports = {
  buttonSave: 'Mentés',
  buttonCancel: 'Mégse',
  buttonYes: 'Igen',
  buttonInput: 'Bevitel',
  buttonConfirm: 'Megerősítés',
  buttonAdd: 'Hozzáadás',
  buttonBack: 'Vissza',
  buttonFilter: 'Szűrés',
  buttonVoid: 'Sztornó',
  buttonDelete: 'Törlés',
  buttonOk: 'OK',
  paymentMethod: 'Fizetési Mód',
  paymentMethodCash: 'Készpénz',
  paymentMethodCard: 'Bankkártya',
  paymentMethodGiftcard: 'Ajándékkártya',
  paymentDirectionIn: 'Befizetés',
  paymentDirectionOut: 'Kifizetés',
  kindService: 'Szolgáltatás',
  kindProduct: 'Termék',
  kindGiftcard: 'Ajándékkártya',
  columnTitleGoods: 'Áru',
  columnTitleUnitPrice: 'Egységár',
  columnTitleQuantity: 'Mennyiség',
  columnTitleDiscount: 'Kedvezmény',
  columnTitleRowSum: 'Tétel',
  columnTitleBalance: 'Egyenleg',
  discount: 'Engedmény',
  discountPercentage: 'Százalék',
  discountAmount: 'Összeg',
  amount: 'Összeg',
  operation: 'Művelet',
  createdBy: 'Munkatárs',
  tip: 'Borravaló',
  category: 'Kategória',
  comment: 'Megjegyzés',
  title: 'Megnevezés',
  details: 'Részletek',
  email: 'E-mail',
  mobile: 'Telefon',
  date: 'Dátum',
  inputTime: 'Beviteli idő',
  quantity: 'Darabszám',
  minute: 'perc',
  minutes: 'perc',
  hour: 'óra',
  hours: 'óra',
  day: 'nap',
  days: 'nap',
  week: 'hét',
  weeks: 'hét',
  inMinutes: 'percben',
  inHour: 'órában',
  total: 'Összesen',
  net: 'Nettó',
  gross: 'Bruttó',
  netTotal: 'Nettó összesen',
  grossTotal: 'Bruttó összesen',
  mainAvg: 'Főátlag',
  search: 'Keresés',
  shop: 'Üzlet',
  price: 'Ár',
  order: 'Sorrend',
  edit: 'Szerkesztés',
  name: 'Név',
  description: 'Leírás',
  barcode: 'Vonalkód',
  settings: 'Beállítások',
  download: 'Letöltés',
  daysAgo: 'napja',
  daily: 'Napi',
  monthly: 'Havi',
  type: 'Típus',
  confirmDeleteTitle: 'Törlés megerősítése',
  confirmDeleteConfirmService: 'Biztosan törölni szeretnéd a szolgáltatást?',
  confirmDeleteConfirmProduct: 'Biztosan törölni szeretnéd a terméket?',
  confirmDeleteConfirmEmployee: 'Biztosan törölni szeretnéd a munkatársat?',
  confirmDeleteConfirmSalaryRule: 'Biztosan törölni szeretnéd a szabály hozzárendelést?',
  confirmDeleteConfirmDeduction: 'Biztosan törölni szeretnéd a kiválasztott levonást?',
  prettyDateFormat: 'YYYY. MMMM D. H:mm',
  loginButton: 'BELÉPÉS',
  signUp: 'PRÓBÁLD KI INGYEN',
  headerIntro: '<h1>\n Salonzone {linebreak} Professzionális szalon menedzsment rendszer\n </h1>\n <h2>\n Online ügyviteli és foglaló rendszerünk átláthatóvá teszi vállalkozásod mindennapi működését. Hozd ki a legtöbbet\n szalonodból!\n </h2>\n <h2>\n <ul>\n <li>Felhő alapú</li>\n <li>Gyors és biztonságos</li>\n <li>Bárhol, bármikor elérhető</li>\n <li>Mobil és asztali eszközön egyaránt</li>\n <li>Teljeskörű ügyfélértesítés, ingyen</li>\n </ul>\n </h2>',
  featuresTitle: 'Professzionális szalon menedzsment rendszer',
  features1: '<FeatureTitle>Időpont foglaló rendszer</FeatureTitle>\n <FeatureText>Könnyed és gyors vendégélmény</FeatureText>\n <FeatureText>Egyszerű kezelés és beállítás</FeatureText>\n <FeatureText>Munkatárs értékelő rendszer</FeatureText>',
  features2: '<FeatureTitle>Beosztások és elemzések</FeatureTitle>\n <FeatureText>Havi beosztások</FeatureText>\n <FeatureText>Szünnapok és szabadságok</FeatureText>\n <FeatureText>Informatív statisztikák</FeatureText>',
  features3: '<FeatureTitle>Adminisztráció</FeatureTitle>\n <FeatureText>Munkatársak kezelése</FeatureText>\n <FeatureText>Szolgáltatások kezelése</FeatureText>\n <FeatureText>Termék készletezés és leltározás</FeatureText>',
  features4: '<FeatureTitle>Napi ügyvitel</FeatureTitle>\n <FeatureText>Szolgáltatás és termék értékesítés</FeatureText>\n <FeatureText>Termékhasználat nyomonkövetése</FeatureText>\n <FeatureText>Visszaélések kiszűrése</FeatureText>',
  features5: '<FeatureTitle>Vezetői kimutatások</FeatureTitle>\n <FeatureText>Összesítők és teljesítmény kimutatások</FeatureText>\n <FeatureText>Tranzakciók nyomonkövetése</FeatureText>\n <FeatureText>Értékesítési statisztikák</FeatureText>',
  features6: '<FeatureTitle>Teljesítményértékelés</FeatureTitle>\n <FeatureText>Automatikus fizetés kalkulációk</FeatureText>\n <FeatureText>Levonások és előlegek kezelése</FeatureText>\n <FeatureText>Fizetési szabályok beállítása</FeatureText>',
  videos1: 'Napi ügyvitel',
  videos2: 'Vezetői kimutatások',
  videos3: 'Adminisztráció',
  videos4: 'Teljesítményértékelés',
  videos5: 'Időpont foglaló rendszer',
  videos6: 'Beosztások és elemzések',
  pricingPackages: 'Csomagok',
  pricingPackageBasic: 'Alaprendszer',
  pricingPackageFull: 'Teljes Rendszer',
  pricingMonthlyPrice: 'Havidíj',
  pricingMonthlyPriceBasic: '14.990 Ft',
  pricingMonthlyPriceFull: '19.990 Ft',
  pricingFeature1: 'Teljes körű ügyvitel',
  pricingFeature2: 'Teljes körű adminisztráció',
  pricingFeature3: 'Vezetői kimutatások',
  pricingFeature4: 'Értékesítési statisztikák',
  pricingFeature5: 'Teljesítményértékelés',
  pricingFeature6: 'Fizetés kalkulátor',
  pricingFeature7: 'Termék készletezés',
  pricingFeature8: 'Korlátlan foglaló rendszer',
  pricingFeature9: 'Beosztások és naptár',
  pricingFeature10: 'e-mail és messenger értesítések',
  pricingFeature11: 'Munkatárs értékelő rendszer',
  pricingFeature12: 'Teljes körű támogatás',
  pricingTryForFree: 'KIPRÓBÁLOM INGYEN',
  pricingSubtitle: 'Árazás',
  pricingOption1: '1 szakemberig',
  pricingOption2: '2 szakemberig',
  pricingOption3: '3 szakemberig',
  pricingOption4: '5 szakemberig',
  pricingOption5: 'Korlátlan szakemberrel',
  pricingParagraph1: '<h2>Próbáld ki a Salonzone rendszerét 2 hónapig ingyen:</h2>\n <p>Nincs kockázat: Próbáld ki kötöttségek nélkül, nincs apróbetűs rész, nincsenek rejtett költségek.</p>\n <p>Gyors beállítás: Mostantól kezdve körülbelül 10 percet vesz igénybe a rendszer felállítása.</p>\n <p>Gyorsan személyre szabható: Miután felállítottad a rendszeredet, percek alatt személyre szabhatod.</p>\n <p>És itt még nincs vége: Most ingyen kipróbálhatod a beépített ügyviteli rendszert is (értékesítések kezelése, készletezés, kimutatások, fizetések és még sok más!).</p>\n <h2>Merülj bele a Salonzone élménybe!</h2>\n <p>A próbaidőszak alatt kipróbálhatod a teljes foglalórendszert és az ügyviteli rendszert is, amin nagyon egyszerű lesz eligazodnod. Ez a teljes Salonzone rendszer, amivel könnyedén kiaknázhatod a vállalkozásodban rejlő lehetőségeket. Hagyd ránk a munka nehezét.</p>',
  aboutUs: '<GetStartedTitle>Rólunk</GetStartedTitle>\n <P>\n A Barbercloud Ltd. borbélyok, fodrászok és más szolgáltató szalonok részére nyújt professzionális ügyviteli és foglaló rendszert. Cégünk piacvezető technológiai megoldásokat használ felhő alapú rendszereiben, hogy ügyfeleink részére a lehető legjobb és legstabilabb ügyviteli megoldásokat nyújtsuk.\n </P>\n <P>\n A Salonzone rendszerét a piaci igényeknek megfelelően fejlesztettük ki, melyet széles körben használnak már ismert borbély szalonok, fodrász üzletek, és egyéb szolgáltatók az Egyesült Királyság és az Európai Unió területén. Az ügyviteli folyamatokat a szalon tulajdonosok folyamatos visszajelzéseit figyelembe véve, maximálisan ügyfeleink igényei szerint alakítottuk ki.\n </P>\n <P>\n Cégünk központja az Egyesült Királyság területén, Londonban található.\n </P>',
  contact: '<GetStartedTitle>Kapcsolat</GetStartedTitle>\n <SubHeader>Értékesítés és ügyfélszolgálat</SubHeader>\n <Paragraph>E-mail: info@salonzone.net</Paragraph>\n <Paragraph>Telefon: +44 7723 567911</Paragraph>\n <Paragraph>(magyar nyelvű ügyfélszolgálat)</Paragraph>',
  companyDetails: '<SubHeader>Cégadatok</SubHeader>\n <Paragraph>Cégnév: Barbercloud Ltd</Paragraph>\n <Paragraph>Székhely: 29 Crowland walk,</Paragraph>\n <Paragraph>Morden, SM4 6QH, United Kingdom</Paragraph>\n <Paragraph>Cégjegyzékszám: 12470538</Paragraph>',
  navFeatures: 'Funkciók',
  navPricing: 'Csomagok',
  navReferences: 'Referenciák',
  navAboutUs: 'Rólunk',
  navContact: 'Kapcsolat',
  pageSignupTitle: 'Próbáld ki rendszerünket 2 hónapig ingyen!',
  pageSignupSubTitle: 'Add meg alap adataidat és ezek alapján egy napon belül felvesszük veled a kapcsolatot! Segítünk beállítani a rendszert, csupán 1-2 óra alatt!',
  pageSignupFormTitle: 'Feliratkozás',
  pageSignupFamilyName: 'Vezetéknév',
  pageSignupGivenName: 'Keresztnév',
  pageSignupShopName: 'Üzlet neve',
  pageSignupWebsite: 'Üzlet webcíme',
  pageSignupEmail: 'E-mail',
  pageSignupCurrentSystem: 'Használsz-e jelenleg foglaló vagy ügyviteli rendszert? Ha igen, melyiket?',
  pageSignupCoupon: 'Promo kód',
  pageSignupSubmitButton: 'Feliratkozás',
  pageSignupTCs: 'A feliratkozással elfogadom a weboldal {linebreak}\n <Link>\n Általános Szerződési Feltételeit\n </Link>',
  pageReviewSuccess: 'Értékelésedet rögzítettük.',
  pageReviewThankyou: 'Köszönjük, hogy minket választottál!',
  pageReviewReview: 'Értékelés:',
  pageReviewComment: 'Megjegyzés',
  pageReviewSubmit: 'Elküld',
  pageReviewFeedbackRequired: 'Szöveges értékelés megadása kötelező',
  pageResetPasswordTitle: 'Új jelszó megadása',
  pageResetPasswordWarningEmptyPassword: 'A jelszó nem lehet üres',
  pageResetPasswordWarningPasswordMismatch: 'A két jelszó nem egyezik!',
  pageResetPasswordNewPassword: 'Új jelszó',
  pageResetPasswordNewPasswordRepeat: 'Új jelszó mégegyszer',
  pageForgotPasswordTitle: 'Új jelszó igénylése',
  pageForgotPasswordEmailOrUsername: 'Felhasználónév',
  pageForgotPasswordSubmit: 'Igénylés elküldése',
  pageCancelBookingTitle: 'Biztosan le szeretnéd modnani a foglalásod?',
  pageCancelBookingCancelBooking: 'Foglalás lemondása',
  appLoginTitle: 'Bejelentkezés',
  appLoginUsername: 'Felhasználónév',
  appLoginPassword: 'Jelszó',
  appLoginLoginButton: 'Bejelentkezés',
  appLoginForgotPasswordButton: 'Elfelejtett jelszó',
  appNavInput: 'Bevitel',
  appNavInputPurchase: 'Értékesítés',
  appNavInputOtherTransaction: 'Egyéb Pénzforgalom',
  appNavInputProductUse: 'Termékhasználat',
  appNavInputCloseTill: 'Kasszazárás',
  appNavReport: 'Kimutatások',
  appNavReportSummary: 'Összesítő',
  appNavReportPerformance: 'Teljesítmény',
  appNavReportTransactions: 'Tranzakciók',
  appNavReportUsages: 'Termékhasználat',
  appNavReportTips: 'Borravalók',
  appNavAdminShop: 'Admin Rendszer Beállítások',
  appNavAdminShopServices: 'Szolgáltatások',
  appNavAdminShopEmployees: 'Munkatársak',
  appNavAdminShopProducts: 'Termékek',
  appNavAdminShopProductInventory: 'Készletezés',
  appNavAdminShopMiscTransactions: 'Egyéb Pénzforgalom',
  appNavAdminShopSettings: 'Beállítások',
  appNavAdminShopTillClosings: 'Kasszazárások',
  appNavAdminBooking: 'Admin Foglalórendszer',
  appNavAdminBookingSettings: 'Foglalás Beállítások',
  appNavAdminBookingSchedules: 'Beosztások',
  appNavAdminBookingStats: 'Statisztikák',
  appNavAdminBookingClients: 'Vendégek',
  appNavAdminBookingReviews: 'Értékelések',
  appNavAdminBookingWebsite: 'Foglaló Weboldal Linkje',
  appNavSalary: 'Admin Fizetések',
  appNavSalaryRules: 'Szabályok',
  appNavSalaryAssignments: 'Hozzárendelések',
  appNavSalaryDeductions: 'Levonás és Előleg',
  appNavSalarySalaries: 'Fizetések',
  appNavBookings: 'Foglalások',
  appNavAlertFreePeriodToExpire: 'Az ingyenes próbaidőszak {days} nap múlva lejár!',
  appNavAlertCooldownToExpire: 'Késedelmes fizetés! Nemfizetés esetén a felhasználói fiókot {days} napon belül befagyasztjuk.',
  appNavLogout: 'Kijelentkezés',
  appNavLogoutConfirmation: 'Biztosan ki akarsz jelentkezni?',
  purchaseErrorTipAmountMismatch: 'Az összeg borravalóval nem lehet kevesebb a végösszegnél!',
  purchaseErrorNoItemAdded: 'Nincs hozzáadott tétel!',
  purchaseErrorProductWithGiftcard: 'Terméket és Ajándékkártyát nem lehet Ajándékkártyával fizetni, kérlek ezeket vidd fel külön.',
  purchaseCurrentTransaction: 'Jelenlegi tranzakció:',
  purchaseYesTip: 'Van',
  purchaseNoTip: 'Nincs',
  purchaseAmountWithTip: 'Összeg borravalóval',
  purchaseAmountWithTipOr: 'VAGY',
  purchaseTipAmount: 'Borravaló összege',
  purchaseConfirm: 'Fizetés megerősítése',
  purchaseConfirmFullAmount: 'Átvett összeg',
  purchaseConfirmTipAmount: 'Ebből borravaló',
  purchaseAddItemsService: 'Szolgáltatás hozzáadása',
  purchaseAddItemsProduct: 'Termék hozzáadása',
  purchaseAddItemsGiftcard: 'Ajándékkártya hozzáadása',
  purchaseAddItemsBookings: 'Foglalások',
  saleItemTableSum: 'Összesen',
  saleItemTableRounding: 'Kerekítés',
  saleItemTableTotal: 'Végösszeg',
  saleItemDialogTitle: '{kind} hozzáadása',
  tillCloseTitle: 'Kasszaforgalom',
  tillCloseOpeningBalance: 'Nyitó kassza',
  tillCloseDailyCashRevenue: 'Napi készpénz bevétel',
  tillCloseDailyCashSalesRevenue: 'Ebből értékesítés',
  tillCloseDailyCashMiscRevenue: 'Ebből egyéb bevétel',
  tillCloseDailyCashExpense: 'Napi készpénz kifizetés',
  tillCloseDailyCashMiscExpense: 'Ebből egyéb kifizetés',
  tillCloseDailyCashTillTakenOut: 'Ebből záráskor kivett KP',
  tillCloseDailyCashBalance: 'Napi készpénz egyenleg',
  tillCloseNoTillCloseYet: 'Még nem volt napi kasszazárás!',
  tillCloseTooMuchInTill: 'Többlet a kasszában!',
  tillCloseNotEnoughInTill: 'Hiány a kasszában!',
  tillCloseMatches: 'A kassza egyezik!',
  tillCloseBanknote: 'Címlet',
  tillCloseCount: 'Darab',
  tillCloseValue: 'Érték',
  tillCloseTotal: 'Összesen',
  tillMissingTitle: 'Hiány kalkuláció',
  tillMissingNoMissing: 'Nincs hiány.',
  tillCloseInputTitle: 'Napi kasszazárás',
  tillCloseInputCloseDate: 'Zárás dátuma',
  tillCloseInputTakenOut: 'Kasszából kivett összeg',
  tillCloseInputNotesLeftInTill: 'Kasszában hagyott váltó címletei',
  bookingBarberInitialsUnassigned: 'MINDEGY',
  bookingCancelled: 'TÖRÖLVE',
  bookingCancelledBreak: 'Törölt szünet',
  bookingCalendarTime: 'Idő',
  bookingCalendarEvent: 'Foglalás',
  bookingCalendarAllDay: 'Egész napos',
  bookingCalendarWeek: 'Hét',
  bookingCalendarWorkWeek: 'Munkahét',
  bookingCalendarDay: 'Nap',
  bookingCalendarMonth: 'Hónap',
  bookingCalendarYesterday: 'Tegnap',
  bookingCalendarTomorrow: 'Holnap',
  bookingCalendarToday: 'Ma',
  bookingCalendarAgenda: 'Napirend',
  bookingCalendarNoEventsInRange: 'Nincsenek foglalások ebben az időszakban.',
  bookingClientNotArrived: 'Nem jelent meg a vendég.',
  bookingCancelDialogTitle: 'Foglalás lemondása',
  bookingCancelDialogConfirmation: 'Biztosan le szeretnéd mondani a foglalást?',
  bookingCancelDialogCancelButton: 'Lemondás',
  bookingBookingTime: 'Időpont',
  calendarToolbarTitle: 'Munkatársak',
  calendarToolbarAllColleagues: 'Összes munkatárs',
  calendarCancelledBookingTitle: 'Lemondott foglalások',
  calendarCancelledBookingPerson: 'Lemondta',
  calendarCancelledBookingCancelTime: 'Lemondás időpontja',
  calendarCancelledBookingBookedTime: 'Foglalt időpont',
  calendarCancelledBookingCancelReason: 'Lemondás indoka',
  calendarCancelledBookingBookedToBarber: 'Kihez foglalt',
  calendarCancelledBookingBookedService: 'Foglalt szolgáltatás',
  calendarCancelledBookingClient: 'Vendég',
  calendarCancelledBookingDetailsDialogTitle: 'Foglalás részletei',
  calendarCancelledBookingDetailsDialogBarberSelected: 'választott',
  calendarCancelledBookingDetailsDialogBarberNotSelected: 'mindegy',
  calendarCancelledBookingDetailsDialogCustomer: 'Vendég',
  startDateEndDateFilterEarlyDateError: 'Adj meg későbbi dátumot',
  startDateEndDateFilterFromLabel: '-tól',
  startDateEndDateFilterToLabel: '-ig',
  summaryRevenueTitle: 'Összbevétel',
  summaryRevenueCard: 'Bankkártyás bevétel',
  summaryRevenueCardService: 'Ebből szolgáltatás',
  summaryRevenueCardProduct: 'Ebből termékértékesítés',
  summaryRevenueCardGiftcard: 'Ebből ajándékkártya-eladás',
  summaryRevenueCash: 'Készpénz bevétel',
  summaryRevenueCashService: 'Ebből szolgáltatás',
  summaryRevenueCashProduct: 'Ebből termékértékesítés',
  summaryRevenueCashGiftcard: 'Ebből ajándékkártya-eladás',
  summaryRevenueMiscRevenue: 'Egyéb bevétel',
  summaryRevenueMiscExpense: 'Egyéb kiadás',
  summaryCustomerTitle: 'Összes Vendég',
  summaryCustomerSum: 'Összesen',
  summaryCustomerGifcard: 'Ajándékkártya',
  summaryCustomerCard: 'Bankkártya',
  summaryCustomerCash: 'Készpénz',
  summaryCustomerPerColleague: 'Munkatársanként',
  summaryMiscCashTitle: 'Egyéb Pénzforgalom',
  summaryMiscCashKind: 'Forgalom Típusa',
  summaryUsageTitle: 'Termékhasználat',
  summarySalesTitle: 'Összes Értékesítés',
  summarySalesDiscountedSales: 'Engedményes értékesítések',
  summarySalesProductService: 'Termék / Szolgáltatás',
  tableFilterLabel: 'Szűrés (min. 3 karakter)',
  calendarEditDialogClientNameMissing: 'Kérlek add meg a neved!',
  calendarEditDialogInvalidEmail: 'Hibás e-mail cím!',
  calendarEditDialogTitleMissing: 'Hiányzik a megnevezés!',
  calendarEditDialogDurationMissing: 'Hibás időtartam!',
  calendarEditDialogEdit: 'Foglalás szerkesztése',
  calendarEditDialogNew: 'Új foglalás bevitele',
  calendarEditDialogKind: 'Bevitel típusa',
  calendarEditDialogBooking: 'Foglalás',
  calendarEditDialogBreak: 'Szünet',
  calendarEditDialogSelectTime: 'Válassz időpontot',
  calendarEditDialogSelectBarber: 'Válassz munkatársat',
  calendarEditDialogDuration: 'Időtartam',
  calendarEditDialogSelectService: 'Válassz szolgáltatást',
  calendarEditDialogClientDetails: 'Add meg a vendég adatait',
  calendarEditDialogBreakDetails: 'Szünet adatai',
  calendarEditDialogExistingCustomers: 'Korábbi vendégek',
  calendarEditDialogExistingCustomerResults: 'találat',
  calendarEditDialogExistingWaitingForInput: 'Kezdj el vendég adatokat bevinni',
  employmentKind: 'Foglalkoztatási forma',
  performanceServiceTitle: 'Szolgáltatások',
  performanceServiceServiceRatio: 'Szolgáltatások %-a az összes személyes forgalomból',
  performanceProductTitle: 'Termékek',
  performanceProductProductRatio: 'Termékek %-a az összes személyes forgalomból',
  performanceProductServiceTitle: 'Szolgáltatások és Termékek',
  performanceProductServicePersonalRatio: 'Személyes összeladás aránya a napi forgalomból',
  performanceStatsTitle: 'Teljesítmény Statisztika',
  performanceStatsDaysWorked: 'Dolgozott napok száma',
  performanceStatsCustomerCount: 'Vendégek száma',
  performanceStatsProductsSold: 'Eladott termékek száma',
  performanceStatsDailyAvgCustomerCount: 'Napi átlagos vendégszám',
  performanceStatsDailyAvgProductSoldCount: 'Napi átlagos eladott termékek száma',
  performanceStatsDailyAvgCustomerValue: 'Átlagos vendégérték (csak szolgáltatás)',
  performanceServiceSaleTitle: 'Szolgáltatásértékesítési Statisztika',
  performanceProductSaleTitle: 'Termékértékesítési Statisztika',
  performanceShortages: 'Mínuszok',
  transactionsInputKind: 'Bevitel Típusa',
  transactionKindMiscPayin: 'Egyéb befizetés',
  transactionKindMiscPayout: 'Egyéb kifizetés',
  transactionKindService: 'Szolgáltatásértékesítés',
  transactionKindProduct: 'Termékértékesítés',
  transactionKindGiftcard: 'Ajándékkártya-értékesítés',
  transactionKindMixed: 'Vegyesértékesítés',
  transactionKindNull: 'Ismeretlen',
  transactionDetailsTitle: 'Tranzakció részletei',
  transactionDetailsVoided: 'SZTORNÓZOTT',
  selectColleague: 'Munkatárs kiválasztása',
  purchaseTableToPay: 'Fizetendő',
  usageDetailsTitle: 'Termékhasználat részletei',
  tipsAllTitle: 'Összes borravaló',
  tipsCashTitle: 'Készpénzes borravaló',
  tipsCardTitle: 'Bankkártyás borravaló',
  tooltipAdminServices: 'Adj hozzá új szolgáltatásokat vagy szerkeszd a meglévőket. Itt tudod beállítani, hogy az általad nyújtott szolgáltatás mennyire összetett.',
  serviceEdit: 'Szolgáltatás szerkesztése',
  serviceAdd: 'Szolgáltatás hozzáadása',
  serviceMultipleOf15Error: 'Csak 5 percnek a többszörösét lehet megadni!',
  serviceAlreadyExists: 'Már létezik szolgáltatás ilyen névvel!',
  serviceNameDescription: 'Név és Leírás',
  serviceNameDescriptionTooltip: 'Szolgáltatás nevének megadása kötelező.\nA szolgáltatás leírása a foglalóoldalon fog megjelenni.',
  barcodeTooltip: 'Add meg a termék/szolgáltatás vonalkódját!',
  servicePriceDuration: 'Ár és Időtartam',
  servicePriceDurationTooltip: "Szolgáltatás árának és időtartamának megadása kötelező. A Pontos Összeg opcióval állíthatod, hogy a foglalóoldalon a pontos összeg jelenjen meg, vagy legyen mögötte '-tól'. Pl. '3000Ft' vagy '3000Ft-tól'.",
  serviceDisplay: 'Megjelenés',
  serviceDisplayTooltip: 'Megadása opcionális, az alacsonyabb sorszámú szolgáltatás előbb jelenik meg a foglalóoldalon. A szolgáltatás a megadott színnel jelenik meg a Foglalások menüben.',
  serviceDisplayOrder: 'Megjelenítési sorrend a foglalóoldalon',
  serviceDefaultLevel: 'Alapértelmezett',
  colorPickerAssignedColour: 'Hozzárendelt szín',
  tooltipAdminProducts: 'Amennyiben követni szeretnéd a termék használatot és/vagy a termék eladásokat, itt adhatsz hozzá új termékeket vagy szerkesztheted a meglévőket.',
  productEdit: 'Termék szerkesztése',
  productAdd: 'Termék hozzáadása',
  productAlreadyExists: 'Már létezik termék ilyen névvel!',
  tooltipAdminEmployee: 'Adj hozzá új munkatársakat vagy szerkeszd a meglévőket. Itt tudod beállítani, hogy a munkatársak milyen szolgáltatást nyújtanak. Beállíthatsz tapasztalati szinteket is a munkatársaknak, ami alapján változik a szolgáltatásuk ára és időtartama, ezt az Admin Rendszer Beállítások menü Beállítások almenüjében teheted meg.',
  employeeRole: 'Szerep',
  employeeEdit: 'Munkatárs szerkesztése',
  employeeAdd: 'Munkatárs hozzáadása',
  employmentKindEmployee: 'Alkalmazott',
  employmentKindContractor: 'Vállalkozó',
  employmentRoleBarber: 'Munkatárs',
  employmentRoleManager: 'Üzletvezető',
  employmentErrorNameMissing: 'Név megadása kötelező!',
  employmentErrorAlreadyExists: 'Már létezik munkatárs ilyen névvel!',
  employmentErrorAvailability: 'Hibás beosztás',
  employmentBaseDetails: 'Alapadatok',
  employmentBaseDetailsTooltip: 'A munkatárs alap adatai, e-mail cím megadása opcionális.',
  employmentBreaks: 'Ismétlődő szünetek',
  employmentBreaksTooltip: 'Opcionális. Megadhatsz ismétlődő szüneteket az adott munkatárshoz, amelyek minden napra bekerülnek a naptárba, ezekre az időpontokra nem lehet foglalni az adott munkatárshoz. Pl.: ebédszünet 12:00-13:00.',
  employmentAddBreak: 'Új szünet hozzáadása',
  employmentAddBreakChooseTime: 'Válassz időpontot',
  duration: 'Időtartam',
  employmentProfileTitle: 'Munkatárs Profil',
  employmentProfileTooltip: 'Megadhatsz profilképet a munkatársnak, ez a profilkép látható lesz a foglaló oldalon. Javasolt félalakos portré fotót feltölteni. Instagram linket lehet megadni a munkatárshoz, ami megjelenik a munkatárs profil oldalán a foglaló oldalon belül.',
  employmentNewProfilePicture: 'Új profilkép feltöltése',
  employmentInstagramLink: 'Instagram link',
  employmentShowProfile: 'Munkatárs megjelenítése a foglaló oldalon',
  employmentServicesTooltip: 'Megadhatod, hogy mely szolgáltatások foglalhatóak az adott munkatárshoz. Alapesetben minden szolgáltatás foglalható minden munkatárshoz.',
  employmentPortfolioImagesTitle: 'Portfólió képek',
  employmentPortfolioImagesTooltip: 'Feltölthetsz portfólió képeket a munkatárs munkáiról, ezek a munkatárs profil oldalán jelennek meg a foglaló oldalon belül. 2, 4, vagy 6 portfólió kép feltöltése javasolt.',
  employmentPortfolioImagesUpload: 'Képek feltöltése',
  employeeDefaultServiceLevel: 'Alap szint',
  inventoryTooltip: 'Itt nyomon követheted a termék készlet fogyását, a szolgáltatásokhoz felhasznált-, és az eladott termékeket is. A TERMÉKEK BEVITELE gombbal a meglévő készlethez hozzá adhatod a beszerzett termékeket; a LELTÁROZÁS gombbal pedig felülírhatod a jelenlegi készleteket. A leltározás végén kimutatást ad a rendszer az összes termék mennyiségének változásáról.',
  inventoryAddProducts: 'Termékek bevitele',
  inventoryDoInventory: 'Leltározás',
  inventoryTableTitle: 'Termékkészlet',
  inventoryProductName: 'Termék neve',
  inventoryQuantityUsed: 'Felhasznált db',
  inventoryQuantitySold: 'Eladott db',
  inventoryQuantityInput: 'Bevitt db',
  inventoryQuantityBalance: 'Jelenlegi készlet db',
  inventoryTableInputTitle: 'Termék bevitelek',
  inventoryInputDetails: 'Bevitel részletei',
  inventoryInputQuantityToAdd: 'Hozzáadott darabszám',
  inventoryCurrentQuantity: 'Jelenlegi darabszám',
  inventoryConfirmInput: 'Bevitel megerősítése',
  inventoryPreviousQuantity: 'Régi mennyiség',
  inventoryNewQuantity: 'Új mennyiség',
  inventoryChangeQuantity: 'Változás',
  miscTransactionTooptip: 'Összesítő kimutatás az egyéb pénzforgalmi tételekről, kategóriánkként, a megadott időszakra. Itt törölhetsz vagy adhatsz hozzá új pénzforgalmi kategóriákat.',
  miscTransactionTableTitle: 'Egyéb Pénzforgalom',
  miscTransactionCategories: 'Egyéb Pénzforgalmi Kategóriák',
  miscTransactionCategoryName: 'Kategória neve',
  miscTransactionCategoriesAdd: 'Új kategória hozzáadása',
  miscTransactionCategoriesDelete: 'Kategória törlése',
  miscTransactionCategoriesMove: 'Meglévő tranzakciók átmozgatása az alábbi kategóriába',
  miscTransactionCategoriesNew: 'Új kategória',
  settingsTill: 'Kasszabeállítás',
  settingsBarberLevels: 'Munkatárs szintek',
  settingsBarberLevelsAdd: 'Új munkatárs szint hozzáadása',
  settingsBarberLevelsAddLevelLabel: 'Szint neve',
  settingsRollBalance: 'Kassza hiány / többlet görgetése',
  settingsRollBalanceTooltip: 'Kasszazárás után, ha hiány vagy többlet keletkezik, a rendszer görgeti a hiányt/ többletet a következő napra.',
  settingsPermissions: 'Szalon hozzáférés jogosultságai',
  settingsAllowDateFiltering: 'Munkatársak szűrhetnek a múltba',
  settingsAllowDateFilteringTooltip: "Ha nincs bejelölve, csak az admin felhasználó szűrhet a múltba az Összesítő és Teljesítmény menükben. Így a másik 'shop felhasználó' amit a munkatársak használnak, csak az adott napi statisztikákat látja. Ennek célja, hogy az alkalmazottak ne lássák a vállalkozás időszakos termelését, csak az adott napot.",
  settingsUploadLogo: 'Új logó feltöltése',
  settingsUploadLogoTooltip: 'A rendszer bal felső sarkában található logót egyéni képre cserélheted.',
  adminTillTooltip: 'Itt lehet megnézni az összes bevitt kasszazárást. Egy adott nap részleteire kattintva megnézheted az összes kasszazárást arra a napra.',
  adminTillListTitle: 'Zárások',
  adminTillListDailyTitle: 'Napi zárások',
  inputDatetime: 'Bevitel ideje',
  adminTillListCloseDay: 'Zárás napja',
  adminTillAmountTaken: 'Kivett összeg',
  adminTillChangeAmount: 'Váltó',
  adminTillCloseCount: 'Zárások száma',
  adminTillNotesInCounter: 'Kasszában hagyott címletek',
  bookingSettingsTooltip: "Itt tudod személyre szabni a foglaló rendszeredet és a foglaló oldaladat.\n \n Minden beállítási opció mellett találsz egy '?' jelet, amivel többet tudhatsz meg az adott beállításról.\n \n Ügyelj rá, hogy minden változtatás után mentened kell az oldal alján található MENTÉS gombbal.\n \n FIGYELEM: Amennyiben bármelyik alap információn változtatsz, mentsd el a változtatásaidat, majd generáld újra az Adatvédelmi Nyilatkozatot és mentsd el újra az oldalt.",
  noMinimum: 'Nincs minimum',
  bookingSettingsErrorOpeningHours: 'Hibás nyitvatartás!',
  bookingSettingsBasicInfo: 'Alapinformációk',
  bookingSettingsBasicInfoTooltip: 'Töltsd ki a vállalkozás alapinformációit. Minden mező megadása kötelező.',
  bookingSettingsShopName: 'Üzlet neve',
  bookingSettingsCompanyName: 'Üzemeltető neve',
  bookingSettingsTaxReference: 'Adószám (ha van)',
  bookingSettingsPhoneNumber: 'Telefonszám',
  bookingSettingsEmail: 'Üzlet e-mail címe',
  bookingSettingsShopAddress: 'Üzlet címe',
  bookingSettingsPostAddress: 'Postacím',
  bookingSettingsPostalCode: 'Irányítószám',
  bookingSettingsTown: 'Város',
  bookingSettingsAddressLine1: 'Utca, Házszám / emelet / ajtó',
  bookingSettingsAddressSame: 'Megegyezik a szalon címével',
  bookingSettingsFacebook: 'Facebook',
  bookingSettingsInstagram: 'Instagram',
  bookingSettingsMetaDescription: 'Google keresési előnézet szövege',
  bookingSettingsMetaDescriptionTooltip: 'Itt megadhatod a weboldal rövid előnézeti szövegét, ami pl. egy google keresés után látszódik.',
  bookingSettingsOpening: 'Nyitvatartás',
  bookingSettingsOpeningTooltip: 'Add meg a nyitvatartási időket minden napra – az időpontokat beírhatod vagy a naptárikon segítségével is megadhatod.',
  bookingSettingsPrivacyPolicy: 'Adatvédelmi Nyilatkozat',
  bookingSettingsPrivacyPolicyTooltip: 'Nagyon fontos, hogy mielőtt a nyilatkozatot legenerálod, el kell menteni a változtatásokat az oldal alján! Az Adatvédelmi Nyilatkozat egy általános nyilatkozat, amelyet a szalonod alapinformációi alapján tölt ki a rendszer. Az Adatvédelmi Nyilatkozatot lehet szerkeszteni, ennek jogi megfelelése a felhasználó felelőssége. \n FIGYELEM: amennyiben a nyilatkozatot szerkeszti a felhasználó, az adatkezelési résznél alapból feltűntetett adatkezelőket mindenképpen fel kell tűntetni a végső nyilatkozatban.',
  bookingSettingsPrivacyPolicyEditButton: 'Adatvédelmi Nyilatkozat szerkesztése',
  bookingSettingsPrivacyPolicyEditTitle: 'Adatvédelmi Nyilatkozat szerkesztése',
  bookingSettingsPrivacyPolicyHeading: 'Az Adatvédelmi Nyilatkozat szövegezése a szalon felelőssége.',
  bookingSettingsPrivacyPolicyReGenerate: 'Újra generálás',
  bookingSettingsPrivacyPolicyCreate: 'Adatvédelmi Nyilatkozat létrehozása',
  bookingSettingsPrivacyPolicyPlaceandDate: 'Nyilatkozat helye és dátuma',
  bookingSettingsPrivacyPolicyGenerate: 'Generálás',
  bookingSettingsPrivacyPolicyCreationHeading: 'Az Adatvédelmi Nyilatkozat létrehozását követően, annak a hatályos magyarországi és EU-s jogszabályoknak történő megfeleléséért, valamint az abban megadott adatokért kizárólag az üzlet üzemeltetője tartozik felelősséggel. A sablon szabadon szerkeszthető.',
  bookingSettingsCloseDates: 'Szünnapok bevitele',
  bookingSettingsCloseDatesTooltip: 'Itt vihetsz be szünnapokat, amikor a szalon zárva van (pl. ünnep napok). Ezekre a napokra nem lehet beosztást bevinni.',
  bookingSettingsBackground: 'Foglalás háttér',
  bookingSettingsBackgroundNew: 'Új foglalás háttér feltöltése',
  bookingSettingsBackgroundTooltip: 'Itt adhatsz meg foglalási hátteret a foglaló oldalhoz.',
  bookingSettingsBanner: 'E-mail banner feltöltése',
  bookingSettingsBannerNew: 'Új e-mail banner feltöltése',
  bookingSettingsBannerTooltip: 'Minden ügyfél értesítő és emlékeztető e-mailedbe elhelyezhetsz egy banner reklámot (kis méretű kép ami egy általad megadott weboldalra visz). Itt hozzáadhatod a képet és a linket, használd ezt a funkciót akciók vagy reklám kampányok futtatásához.',
  bookingSettingsBannerText: 'A bannernek 400x150 vagy 400x100 pixel méretű képnek kell lennie, amennyiben a méret eltér, a rendszer automatikusan átméretezi. A legjobb hatás elérése érdekében a kialakított banner kép mindenképpen a megadott méretű legyen.',
  bookingSettingsBannerSize: 'Banner mérete',
  bookingSettingsBannerSizeSmall: 'Kicsi (400 x 100)',
  bookingSettingsBannerSizeLarge: 'Nagy (400 x 150)',
  bookingSettingsBannerSelectEmails: 'Melyik e-mailekbe kerüljön bele a banner?',
  bookingSettingsBannerLink: 'Banner link - a képre kattintva ez a weboldal nyílik meg',
  bookingSettingsReviewSettings: 'Értékelés beállítások',
  bookingSettingsEmailReview: 'E-mail felkérés küldése szolgáltatás értékelésére.',
  bookingSettingsEmailReviewTooltip: 'Küldjön-e a rendszer értékelési felkérést az ügyfeleknek a foglalt szolgáltatás végeztével? Az értékelés egy 5 csillagos rendszerben történik, illetve szöveges értékelés is megadható.',
  bookingSettingsEmailReviewPeriod: 'Mennyivel a szolgáltatás vége után legyen a felkérés?',
  bookingSettingsAutoAccept4StarReviews: 'Az 4 csillagos, szöveget nem tartalmazó értékelések automatikus elfogadása.',
  bookingSettingsAutoAccept5StarReviews: 'Az 5 csillagos, szöveget nem tartalmazó értékelések automatikus elfogadása.',
  bookingSettingsBookingSettings: 'Foglalási beállítások',
  bookingSettingsMinimumBookingWaitTime: 'Legkorábbi foglalás',
  bookingSettingsMinimumBookingWaitTimeTooltip: 'Itt állíthatod be, hogy a vendégek a szabad időpontok előtt mennyivel tudnak foglalni. Pl ha 30 perc van beállítva, akkor egy 10 perc múlva levő időpontra már nem lehet foglalni. Javasolt „nincs minimum”-ot beállítani.',
  bookingSettingsMaximumBookingWaitTime: 'Legkésőbbi foglalás',
  bookingSettingsMaximumBookingWaitTimeTooltip: 'Legkésőbb ennyivel lehet a jövőbe foglalni, azonban csak a munkatárs beosztások szerint foglalhat a vendég. Javasolt a beosztások elkészítéséhez igazítani, 4-5 hetet megadni.',
  bookingSettingsMinimumCancellationTime: 'Ügyfél foglalás eddig mondható le',
  bookingSettingsMinimumCancellationTimeTooltip: 'A foglalt időpont előtt legkésőbb ennyivel mondhatja le a foglalást az ügyfél. Javasolt “Nincs Minimum” -on tartani, hogy ne legyen rossz a vendég élmény a le nem mondható foglalás miatt.',
  bookingSettingsBookingInterval: 'Ügyfél foglalhat minden',
  bookingSettingsBookingIntervalTooltip: 'Ezzel a foglalható időpontok gyakoriságát határozhatod meg. Ez alapján jelennek meg az ügyfél számára elérhető időpontok a foglaló oldalon.',
  bookingSettingsEmailReminderEnabled: 'E-mail emlékeztető küldése az ügyfélnek',
  bookingSettingsEmailReminderEnabledTooltip: 'Küldjön-e a rendszer emlékeztető üzenetet (e-mail és/vagy facebook messenger) az ügyfélnek a foglalásáról? Megadhatod, hogy mennyi idővel a foglalt időpont előtt menjen ki az emlékeztető.',
  bookingSettingsEmailReminderPeriod: 'A foglalt időpont előtt mennyi idővel kapjon az ügyfél emlékeztetőt?',
  bookingSettingsEmailNotificationEnabled: 'E-mail értesítő minden ügyfél foglalásról központi e-mail címre',
  bookingSettingsEmailNotificationEnabledTooltip: 'Küldjön-e a rendszer értesítést minden ügyfél foglalásról a központi e-mail címre?',
  bookingSettingsEmailNotificationEmailAddress: 'Központi e-mail cím megadása',
  bookingSettingsEmailBarberNotificationEnabled: 'E-mail értesítő minden ügyfél foglalásról a munkatárs e-mail címére',
  bookingSettingsEmailBarberNotificationEnabledTooltip: 'Küldjön-e a rendszer értesítést minden ügyfél foglalásról a szolgáltatást végző munkatárs e-mail címére? Csak akkor működik, ha a munkatársaknak meg van adva az e-mail címe a munkatárs menüben.',
  bookingSettingsSaveChanges: 'Változtatások mentése',
  bookingSettingsonlyReviewsWithFeedback: 'Ügyfél értékelés csak szöveges értékeléssel adható',
  bookingSettingsonlyReviewsWithFeedbackTooltip: 'Ezzel az opcióval a vendég csak akkor tud értékelést adni a munkatársról, ha szöveges értékelést is ad.',
  bookingSchedulesTooltip: 'Itt adod meg a munkatársak beosztását. A táblázat első oszlopában látod a munkatársak nevét és a munkanapok számát az adott hónapban. A táblázat utolsó sorában találod az adott napra beosztott teljes létszámot. Napi beosztást a táblázat egy-egy cellájára kattintva, havi beosztást pedig a munkatárs nevére kattintva adsz meg.',
  bookingSchedulesKind: 'D/ND/SZ',
  bookingSchedulesIsOpening: 'NY',
  bookingSchedulesIsClosing: 'Z',
  bookingSchedulesIsOpeningAndClosing: 'NY/Z',
  bookingSchedulesDayStart: 'Munka kezdete',
  bookingSchedulesDayEnd: 'Munka vége',
  bookingSchedulesWorkKind: 'Munka',
  bookingSchedulesWorking: 'Dolgozik (D)',
  bookingSchedulesNotWorking: 'Nem dolgozik (ND)',
  bookingSchedulesHoliday: 'Szabadnap (SZ)',
  bookingSchedulesOpening: 'Nyitás',
  bookingSchedulesClosing: 'Zárás',
  bookingSchedulesLegend: 'Magyarázat',
  bookingSchedulesShopClosed: 'Üzlet zárva',
  bookingSchedulesShopOpening: 'Üzlet nyitás',
  bookingSchedulesShopClosing: 'Üzlet zárás',
  bookingSchedulesNumberOfEmployees: 'Létszám',
  statsTooltip: 'Itt látod a foglalási statisztikákat.',
  statsAllBooking: 'Foglalások száma',
  statsKpiCancelledBooking: 'Törölt foglalások',
  statsKpiNoShow: 'Nem jelent meg',
  statsKpiInternalBooking: 'Belső foglalás',
  statsRatioReturningCustomers: 'Visszatérő ügyfelek',
  statsRatioOnlineBookings: 'Online foglalások',
  statsRatioBarberSelected: 'Ügyfél választott munkatársat',
  clientsTooltip: 'Itt megnézheted, törölheted vagy letöltheted a vendég adatokat.\n \n A személyes adatok hatékony kezelése nagyon fontos a vállalkozásod számára. Ezen az oldalon minden fontos eszköz a rendelkezésedre áll, hogy megfelelj a GDPR előírásainak.\n \n Ne feledd, a vendégeid kikérhetik a róluk tárolt információt (szűrés és letöltés); vagy felkérhetnek hogy töröld az információt (kattints a részletekre, majd vendég adatok törlése).\n \n Ne feledd, hogy ügyfeleid adatait senkivel nem oszthatod meg.',
  clientsClientName: 'Vendég neve',
  clientsLastBookingDate: 'Legutóbbi foglalás dátuma',
  clientsLastBookingService: 'Legutóbbi szolgáltatás',
  clientsLastBookingBarber: 'Legutóbb kihez foglalt',
  clientsClientDetails: 'Vendég részletei',
  clientsClientEmail: 'Vendég e-mail címe',
  clientsClientPhone: 'Vendég telefonszáma',
  clientsFirstBookingDate: 'Első foglalás dátuma',
  clientsBookingDate: 'Foglalás dátuma',
  clientsBookingTime: 'Foglalás ideje',
  clientsBookingSource: 'Hol foglalt',
  clientsBookingBarber: 'Kihez foglalt',
  clientsBookingSourceInternal: 'Rendszerben foglalt',
  clientsBookingSourceExternal: 'Online foglalt',
  clientsDeleteClient: 'Vendég adatok törlése',
  clientsDeleteConfirmation: 'Az adatok véglegesen törlődnek, biztos vagy benne, hogy törölni akarod?',
  reviewsTooltip: 'Itt nézheted meg a beérkezett munkatárs értékeléseket. Az értékelés részleteire kattintva megnézheted a vendég és a foglalás részleteit és válaszolhatsz a vendégnek privát e-mailben. Az üzenetet a rendszer megcímezi és az üzleted nevében aláírja, így neked csak a tartalmi rész kell beírnod ide.\n \n Itt döntheted el, hogy a beérkezett értékelést elfogadod vagy elutasítod. Amennyiben elfogadod az értékelést, kb. 5 percen belül meg fog jelenni a foglaló oldalon a munkatárs profilján, illetve beleszámít a munkatárs átlagos értékelésébe.',
  reviewsUnprocessed: 'Beérkezett Értékelések',
  reviewsApproved: 'Elfogadott Értékelések',
  reviewsDenied: 'Elutasított Értékelések',
  review: 'Értékelés',
  reviewDetails: 'Értékelés részletei',
  reviewClientDetails: 'Ügyfél adatai',
  reviewAllBookingCount: 'Összes foglalások száma',
  reviewBookingDetails: 'Foglalás adatai',
  reviewToWho: 'Kihez',
  reviewToWhen: 'Mikorra',
  reviewToWhatService: 'Milyen szolgáltatásra',
  reviewReviewDetails: 'Értékelés adatai',
  reviewSendResponse: 'Válasz küldése',
  bookingSendMessage: 'Üzenet küldése',
  reviewAccept: 'Elfogadás',
  reviewDeny: 'Elutasítás',
  barberName: 'Munkatárs neve',
  send: 'Küldés',
  response: 'Válasz',
  message: 'Üzenet',
  reviewSendResponseConfirm: 'Válasz küldés megerősítése',
  reviewSendResponsePreview: 'Az üzenet részletei',
  reviewSendResponseConfirmMessage: 'Üzenet',
  bookingSendMessageConfirm: 'Üzenet küldés megerősítése',
  bookingSendMessagePreview: 'Az üzenet részletei',
  bookingSendMessageConfirmMessage: 'Üzenet',
  websiteTooltip: 'Ezen az oldalon találod a foglaló weboldalad linkjét.\n \n Az egyedi azonosítóval megváltoztathatod a foglaló linked végét. A google keresési előnézet szövegét, illetve beállíthatsz egy figyelmeztető szöveget is, amit a rendszer megjelenít a foglaló oldaladon (pl. Figyelem, áraink megváltoztak).\n \n Ne felejtsd el elmenteni a változtatásaidat az oldal alján a MENTÉS gombbal.',
  website: 'Weboldal',
  open: 'Megnyitás',
  salaryRulesTooltip: 'Itt hozhatsz létre fizetési szabályokat. Válaszd ki a szabály típusát, nevezd el a szabályt, majd add meg a részleteket. Akármennyi szabályt létrehozhatsz, és egy munkatárshoz több szabályt is hozzárendelhetsz, így akár nagyon komplex, munkatársanként eltérő fizetési rendszereket is be lehet állítani. A Hozzárendelések menüpont alatt rendelheted a munkatársakhoz a létrehozott szabályokat.\n \n A saját bankkártya terminálos munkatárs szabálytípus segít a magán vállalkozó munkatársak saját terminálon értékesített összeladásait levonni a végső fizetendő összegből, megkönnyítve a fizetés kalkulációt.',
  salaryRulesAddNew: 'Új szabály hozzáadása',
  fixsalaryrule: 'Fix fizetés',
  performancebasedsalaryrule: 'Teljesítmény arányos fizetés',
  tieredsalaryrule: 'Sávos teljesítmény bónusz',
  tieredprogressivesalaryrule: 'Sávos progresszív fizetés',
  deductcardpaymentfromsalaryrule: 'Saját Bankkártya Terminálos Munkatárs',
  deductcardpaymentfromsalaryruleTooltip: 'Azoknak a munkatársaknak, akiknek saját bankkártya termináljuk van, ennek a szabálytípus hozzárendelésével, a bankkártyás fizetések összegét levonhatod a fizetésből.',
  salaryRules: 'Szabályok',
  salaryName: 'Szabály neve',
  salaryBase: 'Fizetés alapja',
  salaryType: 'Szabály típusa',
  salaryAmount: 'Szabály összege',
  salaryRule: 'Szabály',
  salaryCardServiceNet: 'Bankkártyás szolgáltatások nettó értéke',
  salaryCardServiceGross: 'Bankkártyás szolgáltatások bruttó értéke',
  salaryCardProductNet: 'Bankkártyás termékértékesítések nettó értéke',
  salaryCardProductGross: 'Bankkártyás termékértékesítések bruttó értéke',
  salaryGiftcardServiceNet: 'Ajándékkártyás szolgáltatások nettó értéke',
  salaryGiftcardServiceGross: 'Ajándékkártyás szolgáltatások bruttó értéke',
  salaryCashServiceNet: 'Készpénzes szolgáltatások nettó értéke',
  salaryCashServiceGross: 'Készpénzes szolgáltatások bruttó értéke',
  salaryCashProductNet: 'Készpénzes termékértékesítések nettó értéke',
  salaryCashProductGross: 'Készpénzes termékértékesítések bruttó értéke',
  salaryDeductionBase: 'Fizetés levonás alapja',
  salaryTieredSalaryRuleDialogTitle: 'Sávos teljesítmény bónusz',
  salaryProvideName: 'Add meg a szabály nevét!',
  salarySalaryBase: 'Add meg a számítás alapját!',
  salaryTierHelper: 'Az alábbi táblázatban add meg a sávok határát és az azok elérésekor járó bónuszt / értéket.',
  salaryTierMax: 'Értékhatár',
  salaryTierBonus: 'Bónusz',
  salaryTierBonusPercentage: 'Százalék',
  salaryTierLimit: 'Limit',
  salaryTierPercentage: 'Teljesítmény százaléka',
  salaryProgressiveTieredTitle: 'Sávos progresszív fizetés',
  salaryPerformanceBasedTitle: 'Teljesítmény arányos fizetés',
  salaryPerformancePercentage: 'Fizetés alapjának százaléka',
  salaryFixTitle: 'Fix napi vagy havi fizetés',
  salaryAssignmentsTooltip: 'Itt tudod a létrehozott Fizetési Szabályokat hozzárendelni a munkatársakhoz. Egy munkatárshoz több szabályt is hozzárendelhetsz.',
  salaryAssignmentsNew: 'Új hozzárendelés',
  salaryAssignmentsAssign: 'Hozzárendelés',
  salaryAssignmentsAssignedRules: 'Hozzárendelt szabályok',
  salaryDeductionTooltip: 'Itt tudsz bevinni előlegeket vagy levonásokat a munkatársakhoz, amelyek meg fognak jelenni a Fizetések oldalon.',
  salaryDeductionDeduction: 'Levonás',
  salaryDeductionAdvance: 'Előleg',
  salaryDeductionDeductionNew: 'Új levonás',
  salaryDeductionDeductionDeductedBy: 'Tételt létrehozó munkatárs',
  salaryDeductionDeductionDeductFrom: 'Érintett Munkatárs',
  salaryDeductionDeductionDate: 'Levonás dátuma',
  salaryDeductionAdvanceDate: 'Előleg dátuma',
  salaryDeductionAdvanceNew: 'Új előleg',
  salaryDeductionAdvanceCashFromTill: 'Kasszából fizetve?',
  salaryDeductionList: 'Levonások és Előlegek',
  salaryDeductionDetails: 'Levonás részletei',
  salaryDeductionDetailsDeductedBy: 'Munkatárs, aki a tételt bevitte',
  deletionDate: 'Törlés dátuma',
  deletionBy: 'Törölte',
  salarySalariesTableTitle: 'Fizetés kalkuláció',
  salarySalariesTooltip: 'Ez az oldal mutatja a szabályok, hozzárendelések, levonások és előlegek alapján számolt fizetést és a még fizetendő összeget a kiválasztott időszakra.',
  salarySalariesTotalSalary: 'Teljes fizetés',
  salarySalariesPartialSalary: 'Részösszeg',
  layoutDatetimeFormat: 'YYYY-MM-DD dddd',
  customUrl: 'Egyedi link azonosító',
  bookingBook: 'Foglalás',
  shopDetailsBooking: 'Foglalás',
  shopDetailsBookNow: 'Foglalás most',
  bookingBarbers: 'Munkatársak',
  bookingBarbersMore: 'További munkatársak',
  bookingAboutUs: 'Rólunk',
  bookingContact: 'Kapcsolat',
  bookingOpeningHoursMonday: 'Hétfő',
  bookingOpeningHoursTuesday: 'Kedd',
  bookingOpeningHoursWednesday: 'Szerda',
  bookingOpeningHoursThursday: 'Csütörtök',
  bookingOpeningHoursFriday: 'Péntek',
  bookingOpeningHoursSaturday: 'Szombat',
  bookingOpeningHoursSunday: 'Vasárnap',
  bookingOpeningHoursClosed: 'Zárva',
  bookingBarbersBook: 'Foglalás',
  bookingBookNoPreference: 'Mindegy, kihez megyek',
  bookingBookServiceStepTitle: 'Válassz szolgáltatást',
  bookingBookExtrasStepTitle: 'Extra szolgáltatások hozzáadása',
  bookingBookServiceFormat: '{service} (kb. {duration} perc) - {price}',
  bookingBookBarberStepTitle: 'Válassz munkatársat',
  bookingBookDateStepTitle: 'Válassz ki egy időpontot',
  bookingBookCustomerStepTitle: 'Add meg az adataidat',
  bookingBookBackService: 'Más szolgáltatást választok',
  bookingBookBackExtras: 'Extra szolgáltatások módosítása',
  bookingBookBackBarber: 'Más munkatársat választok',
  bookingBookBackDate: 'Más időpontot választok',
  bookingErrorTCs: 'Kérlek fogadd el az Adatvédelmi Nyilatkozatot.',
  bookingErrorName: 'Kérlek add meg a neved',
  bookingErrorEmail: 'Kérlek add meg az e-mail címed',
  bookingErrorInvalidEmail: 'Hibás e-mail cím!',
  bookingErrorMobile: 'Kérlek add meg a mobil számod',
  bookingService: 'Szolgáltatás',
  bookingBarber: 'Munkatárs',
  bookingAppointmentDate: 'Dátum:',
  bookingAppointmentTime: 'Időpont:',
  bookingAppointmentAm: 'Délelőtt',
  bookingAppointmentPm: 'Délután',
  bookingAppointmentEvening: 'Este',
  bookingAppointmentNoFreeSlots: 'Nincs szabad idõpont erre a napra.',
  bookingCustomerName: 'Név',
  bookingCustomerEmail: 'E-mail',
  bookingCustomerMobile: 'Telefon',
  bookingCustomerAcceptTCs: 'A foglalás leadásával elfogadod az Adatvédelmi Nyilatkozatunkat, amelyet <A>ide</A> kattintva elolvashatsz.',
  bookingCustomerRememberDetails: 'Foglalási adatok megjegyzése',
  bookingCustomerBook: 'Foglalás',
  bookingDefaultSEOTitle: 'Foglalás',
  bookingFollowSteps: 'Kövesd a lépéseket a foglalás leadásához.',
  barberReviewsBackToProfile: 'Vissza a profilra',
  bookingSuccessDialogTitle: 'Foglalás megerősítése',
  bookingSuccessDialogContent: '<DialogHeading>Köszönjük foglalásod!</DialogHeading> <DialogText>A megadott e-mail címre hamarosan egy megerősítő üzenet fog érkezni.</DialogText>',
  bookingCancelSuccessDialogTitle: 'Lemondás megerősítése',
  bookingCancelSuccessDialogContent: '<DialogHeading>Foglalás lemondása sikeres volt.</DialogHeading> <DialogText>A megadott e-mail címre hamarosan egy megerősítő üzenet fog érkezni.</DialogText>',
  snackbarInputSuccess: 'Sikeres bevitel!',
  snackbarCloseTillSuccess: 'Sikeres kassza zárás!',
  snackbarProductUseInputSuccess: 'Sikeres termékhasználat bevitel!',
  snackbarVoidSuccess: 'Sikeres sztornó!',
  snackbarModifySuccess: 'Sikeres módosítás!',
  snackbarCreateSuccess: 'Sikeres bevitel!',
  snackbarDeleteSuccess: 'Sikeres törlés!',
  snackbarAddSuccess: 'Sikeres hozzáadás!',
  snackbarAddProductInventorySuccess: 'Sikeres termék bevitel!',
  snackbarAddProductInventoryError: 'Hiba történt a bevitel során!',
  snackbarAddRuleSuccess: 'Sikeres szabály bevitel!',
  snackbarAssignRuleSuccess: 'Sikeres szabály hozzárendelés!',
  snackbarUnassignRuleSuccess: 'Szabály hozzárendelés törölve!',
  snackbarBookingSuccess: 'Sikeres foglalás!',
  snackbarLoginError: 'Sikertelen belépés!',
  snackbarLogoutSuccess: 'Sikeres kijelentkezés!',
  snackbarForgotPasswordSent: 'Igény elküldve.',
  snackbarResetPasswordSuccess: 'Sikeres jelszó módosítás!',
  snackbarResetSignupSuccess: 'Köszönjük a feliratkozást!',
  snackbarResetSignupError: 'Feliratkozás közben hiba történt!',
  bookingEmailSlugConfirmationEmail: 'Foglalás megerősítő e-mail',
  bookingEmailSlugReminderEmail: 'Foglalás emlékeztető e-mail',
  bookingEmailSlugCancellationEmail: 'Lemondás megerősítő e-mail',
  bookingEmailSlugReviewReminderEmail: 'Szolgáltatást értékelő e-mail',
  appNavHelp: 'Segítség',
  bookingMoreServices: 'További szolgáltatások',
  bookingMoreReviews: 'További vélemények',
  bookingSettingsWarningMessage: 'Figyelmeztető üzenet megjelenítése',
  bookingSettingsWarningMessageTooltip: 'Itt beállíthatsz egy figyelmeztető szöveget, amit megjelenítünk a foglaló oldaladon (pl. Figyelem, áraink megváltoztak).',
  barberMinimumServicePriceFormat: '{price}-tól',
  tooltipSettingsBarberLevels: 'Ha egy szolgáltatás ára munkatársanként eltér, érdemes különböző Munkatárs szinteket beállítani (pl. Mester, Kezdő vagy hasonló). A megadott szintek szerint lehet változtatni a szolgáltatások árát és időtartamát. Ezek megjelennek a foglaló oldaladon.\r\n\n1. Hozz létre különböző MUNKATÁRS SZINTEKET itt és adj nekik neked tetsző megnevezéseket. Mikor létrehoztad a szinteket nyomd meg a MENTÉS gombot.\r\n\n2. Menj az ADMIN RENDSZER BEÁLLÍTÁSOK / SZOLGÁLTATÁSOK menübe. A szolgáltatások szerkesztésénél az ÁR ÉS IDŐTARTAM opciónál kapcsold be a szolgáltatás további szintjeit és add meg a hozzá tartozó árat és időtartamot. Mikor végeztél nyomd meg a MENTÉS gombot, majd szerkeszd a többi szolgáltatást.\r\n\n3. Menj az ADMIN RENDSZER BEÁLLÍTÁSOK/ MUNKATÁRSAK menübe. A munkatárs szerkesztésénél állítsd be, hogy milyen a SZOLGÁLTATÁSOKAT milyen szinten nyújt. Mikor végeztél nyomd meg a MENTÉS gombot, majd szerkeszd a többi munkatársat.',
  tooltipViewVideo: 'Kattints a gombra a videó megtekintéséhez.',
  tooltipBookings1: 'Foglalások kezelése - interaktív és intuitív.',
  tooltipBookings2: 'Foglalások & szünetek létrehozása belsőleg - pillanatok alatt.',
  extraBookingSelectExtraBarbers: 'Munkatárs kiválasztása az extra szolgáltatásokhoz:',
  serviceNextServicesTitle: 'Kapcsolódó szolgáltatások',
  serviceNextServicesTooltip: 'Ha szeretnél egy szolgáltatáshoz egy vagy több másik szolgáltatást kapcsolni, itt tudod beállítani. Így a vendégeid a foglalóoldalon több szolgáltatást együtt is le tudnak foglalni.\n\n1. Állítsd be, hogy az adott szolgáltatáshoz képest (amit éppen szerkesztesz) a többi szolgáltatás előtte vagy utána következik. Pl. mosás után szárítás.\n\n2. A tiltás beállításával zárd ki azokat a szolgáltatásokat, amiket nem akarsz vagy nem lehet összekapcsolni az adott szolgáltatással (amit éppen szerkesztesz).\n\n3. Mielőtt elhagyod az oldalt, nyomd meg a MENTÉS gombot.',
  serviceNextServicesBeforeAfter: 'Előtte / Utána / Tiltás',
  claimEmployee: 'Munkatárs',
  claimEmployees: 'Munkatárs',
  claimTitle: 'Bizonyos dolgoknak egyszerűnek kell lennie',
  claimSubtitle: '- Egy időpont foglalás is ilyen.',
  claimSubtitle2: 'Töltsd ki a hiányzó adatokat, és állítsd fel az ingyenes foglalórendszeredet.',
  claimFormName: 'Név:',
  claimFormShopName: 'Üzleted neve:',
  claimFormEmail: 'e-mail cím:',
  claimFormPhone: 'Telefonszám:',
  claimCTA: 'FOGLALÓRENDSZER IGÉNYLÉSE',
  claimAcceptTCs: 'A gombra kattintva elfogadod a Barbercloud Ltd. <LinkPP>Adatvédelmi Nyilatkozatát</LinkPP> és <LinkTC>Felhasználási Feltételeket</LinkTC>.',
  claimSubHeading1: 'A Salonzone-al a foglalás gyerekjáték:',
  claimSubHeading2: 'Néhány fontos információ a foglalórendszereddel kapcsolatban:',
  claimSubHeading2Li1: '<Bold>Nincs kockázat:</Bold> Próbáld ki kötöttségek nélkül, nincs apróbetűs rész, nincsenek rejtett költségek.',
  claimSubHeading2Li2: '<Bold>Gyors beállítás:</Bold> Mostantól kezdve körülbelül 10 percet vesz igénybe a rendszer felállítása.',
  claimSubHeading2Li3: '<Bold>Gyorsan személyre szabható:</Bold> Miután felállítottad a rendszeredet, percek alatt személyre szabhatod.',
  claimSubHeading2Li4: '<Bold>És itt még nincs vége:</Bold>A próbaidőszak alatt kipróbálhatod a beépített ügyviteli rendszert is (értékesítések kezelése, készletezés, kimutatások, fizetések és még sok más!).',
  claimSubHeading3: 'Merülj bele a Salonzone élménybe!',
  claimSubHeading3p: 'A próbaidőszak alatt kapsz egy foglalórendszert és egy teljes ügyviteli rendszert is, amin nagyon egyszerű eligazodni. Ez a teljes Salonzone rendszer, amivel könnyedén kiaknázhatod a vállalkozásodban rejlő lehetőségeket. Hagyd ránk a munka nehezét.',
  claimSubHeading4: 'A rendszer havi díja a próbaidőszak után:',
  claimPricingCol1: 'Csomag',
  claimPricingCol2: 'Foglalórendszer',
  claimPricingCol3: 'Foglaló & Ügyviteli rendszer',
  claimSuccess: 'Szép munka! Kattints a gombra és hozd létre az Admin fiókod jelszavát. E-mailben megkapod az Admin fiókodhoz tartozó felhasználó nevedet is.',
  claimSuccessCTA: 'TOVÁBB A FOGLALÓRENDSZEREDHEZ',
  price11: '5.990 Ft',
  price12: '6.990 Ft',
  price21: '8.990 Ft',
  price22: '9.990 Ft',
  price31: '10.990 Ft',
  price32: '11.990 Ft',
  price41: '13.990 Ft',
  price42: '14.990 Ft',
  price51: '17.990 Ft',
  price52: '19.990 Ft',
  onboardingShopDialogTitle: 'Kérlek töltsd ki a hiányzó adatokat és ellenőrizd le a meglévőket.',
  onboardingShopDialogOwnerName: 'Az üzlet tulajdonosának teljes neve (személy, egyéni vállalkozó vagy vállalkozás)',
  onboardingShopDialogCompanyNumber: 'Adószám (amennyiben vállalkozás vagy EV)',
  onboardingShopDialogAddress: 'Cím:',
  onboardingShopDialogAddressLine1: 'Utca, házszám',
  onboardingShopDialogAddressLine2: 'Elemelet, ajtó (ha van)',
  onboardingShopDialogAddressCity: 'Város',
  onboardingShopDialogAddressPostcode: 'Irányítószám',
  onboardingShopDialogAddressCountry: 'Ország',
  onboardingSuccessDialogTitle: 'A rendszer felállítása kész!',
  onboardingSuccessDialogp1: "A foglaló oldalad 5-10 percen belül elérhető lesz. Amíg vársz, állítsd be a munkatársak beosztását és fedezd fel a többi a menüt, hogy finomhangold a foglaló oldalad a saját igényeid szerint. Minden oldalon találhatóak '?' jelek, amik segítenek eligazodni.",
  onboardingSuccessDialogp2: "Továbbá küldtünk neked egy e-mailt a másodlagos felhasználóneveddel (ezt 'shop accountnak' hívjuk). Ezt állítsd be a csapatod tagjai számára, a napi szintű használathoz (ez egy nem Admin fiók).",
  onboardingSuccessCTA: 'UGRÁS A BEOSZTÁSOK OLDALRA',
  onboardingOpeningHourDialogTitle: 'Állítsd be a nyitvatartási időt',
  onboardingOpeningHourDialogHelper: 'Állítsd be a nyitvatartási időt és vedd ki a pipát a hét azon napjainál, amelyeken zárva van az üzlet.',
  onboardingBarberDialogTitle: 'Állítsd fel a csapatodat',
  onboardingBarberDialogHelper: 'Ezeket a neveket fogják látni a vendégek, amikor időpontot foglalnak.',
  onboardingBarberDialogTeamMember: 'Munkatárs',
  onboardingAddMore: 'További hozzáadása',
  onboardingServiceDialogTitle: 'Állítsd be a szolgáltatásokat',
  onboardingServiceDialogHelper: 'Ezek azok a szolgáltatások, amiket a vendégeid foglalhatnak.',
  onboardingServiceDialogServiceCol: 'Szolgáltatás',
  onboardingServiceDialogPriceCol: 'Szolgáltatás ára',
  onboardingServiceDialogDurationCol: 'Szolgáltatás időtartama',
  onboardingServiceDialogDuplicateError: 'Hiba: Már létezik a szolgáltatás',
  onboardingServiceDialogNumberError: 'Adj meg egy érvényes számot',
  onboardingDialogTitle: 'A rendszer felállítása - A befejezése körülbelül 10 perc',
  onboardingDialogStep1Title: 'Csapat',
  onboardingDialogStep2Title: 'Szolgáltatások',
  onboardingDialogStep3Title: 'Nyitvatartási idő',
  onboardingDialogStep4Title: 'Üzlet adatai',
  onboardingDialogStep5Title: 'Adatvédelmi Nyilatkozat',
  onboardingDialogStep1: 'Állítsd fel a csapatod - a megadott adatokat később is szerkesztheted vagy hozzáadhatsz további munkatársakat.',
  onboardingDialogStep2: 'Állítsd be a szolgáltatásokat, azok árát és időtartalmát - a megadott adatokat később szerkesztheted, vagy hozzáadhatsz továbbiakat.\n \n Ha az adott szolgáltatásnak több árbesorolása van, az alapján, hogy ki végzi, akkor ezt később beállíthatod az Admin-Shop/Beállítások menüben.',
  onboardingDialogStep3: 'Állítsd be a nyitvatartási időt - ezt később bármikor megváltoztathatod.',
  onboardingDialogStep4: 'Ellenőrizd, hogy a már meglévő adatok helyesek-e és töltsd ki a hiányzó mezőket, hogy minden pontos legyen.',
  onboardingDialogStep5: 'Automatikusan létrehozunk egy személyre szabott Adatvédelmi Nyilatkozatot a foglalási weboldaladhoz. Ennek ellenére a jogi felelősség a tiéd - később áttekintheted és módosíthatod a nyilatkozatot, ha szeretnéd.',
  onboardingDialogStepUnknown: 'Ismertlen lépés',
  onboardingDialogNext: 'Tovább',
  onboardingPolicyTitle: 'Adatvédelmi Nyilatkozat létrehozása',
  onboardingPolicyBody: 'Egyelőre elég megadnod a nyilatkozat létrehozásának helyét és idejét. Az Adatvédelmi Nyilatkozatodat a rendszer automatikusan legenerálja, ezt később áttekintheted és módosíthatod, ha szeretnéd.',
  onboardingPolicyDisclaimer: 'A te felelősséged biztosítani, hogy az Adatvédelmi Nyilatkozat a célnak megfeleljen és jogilag rendben legyen.',
  claimSetAdminPassword: 'Adj meg egy jelszót az Admin fiókodhoz',
  servicePriceExact: 'Pontos összeg',
  miscSaleAmount: 'Egyéb költség',
  extraSreviceBefore: '{title} előtt',
  extraSreviceAfter: '{title} után',
  claimInvalidShop: 'Ezt a rendszert már legenerálták. Ha kérdésed van, lépj velünk kapcsolatba az info@salonzone.net e-mail címen, vagy lépj be a rendszeredbe most.',
  countryCzechRepublic: 'Česko',
  countryEngland: 'England',
  countryHungary: 'Magyarország',
  countryNorthernIreland: 'Northern Ireland',
  countryScotland: 'Scotland',
  countrySlovakia: 'Slovensko',
  countryWales: 'Wales',
  onboardingShopDialogDialogTitle: 'Üzlet adatai',
  errorDuplicateTeamMember: 'Figyelem, azonos nevű munkatárs!',
  errorWebsiteStillBuilding: 'A foglaló oldaladat épp most készítjük elő, próbáld meg újra 5-10 perc múlva.',
  tooltipAdminProductUsage: "Itt láthatod az összes bevitt termék használatot egy adott időszakra. Minden tranzakciót részletesen átnézhetsz és sztornóhatsz is a Részletek oszlopban a '...'-ra kattintva. A sztornózott tételeket piros színnel jelöljük.",
  tooltipAdminTransactions: "Itt láthatod az összes bevitt értékesítést és egyéb pénzforgalmat egy adott időszakra. Minden tranzakciót részletesen átnézhetsz és sztornóhatsz is a Részletek oszlopban a '...'-ra kattintva. A sztornózott tételeket piros színnel jelöljük.",
  tooltipCloseTill: 'Itt gyors áttekintést kapsz a napi készpénz forgalomról. Érdemes napiszinten kasszát zárni (a munkaidő végén), hogy a jövőben áttekinthető legyen az adott napi forgalmad. Egy adott napon belül egy újabb kasszazárás felülírja az előzőt.',
  tooltipProductUse: 'A munkád során felhasznált termékek fogyását vezetheted itt. Ezek levonásra kerülnek a termékkészletből. A bevitt fogyást a Kimutatások menü Termékhasználat almenüjében tudod sztornózni.',
  tooltipMiscTransactions: 'Itt tudod felvinni az egyéb bevételeket és kiadásokat, pl. kávé, villanyszámla, takarítás költsége, stb. A készpénzes tételek beleszámítanak a kassza napi egyenlegébe.',
  tooltipInputPurchase: 'Itt tudod felvinni az értékesítéseket ami alapján kimutatásokat készít a rendszer. Sztornózni a Kimutatások menüben, a Tranzakciók almenüben tudsz.',
  allFieldsRequiredErrorMessage: 'A csillaggal jelölt mezők kitöltése kötelező.',
  purchaseErrorPaymentMethodMissing: 'Fizetési mód megadása kötelező!',
  bookingCustomerNotes: 'Ügyfél jegyzetek',
};